import { EQPAPI } from "../../api/api";

const SET_USER = 'userReducer/SET_USER';
const SET_USERSTAT = 'userReducer/SET_USERSTAT';
const SET_PURCHASES = 'userReducer/SET_PURCHASES';
const SET_USERS = 'userReducer/SET_USERS';
const SET_ORG = 'userReducer/SET_ORG';
const SET_LOADER = 'userReducer/SET_LOADER';
const SET_ORGLOADER = 'userReducer/SET_ORGLOADER';
const SET_ERROR = "userReducer/ERROR";

const initialState = {
    user: null,
    userstat: null,
    users: null,
    org: null,
    error: null,
    loader: false,
    orgloader: false,
    purchases: null
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.user
            }
        case SET_USERSTAT:
            return {
                ...state,
                userstat: action.userstat
            }

        case SET_PURCHASES:
            return {
                ...state,
                purchases: action.purchases
            }             
        case SET_USERS:
            return {
                ...state,
                users: action.users
            }            
        case SET_ORG:
            return {
                ...state,
                org: action.org
            }
        case SET_ERROR:
            return {
                ...state,
                error: action.error
            }
        case SET_LOADER:
            return {
                ...state,
                loader: action.loader
            }    
        case SET_ORGLOADER:
            return {
                ...state,
                orgloader: action.orgloader
            }                          
        default:
            return state;
    }
}


export const getUser = state => state.userReducer.user
export const getUserStat = state => state.userReducer.userstat
export const getPurchases = state => state.userReducer.purchases
export const getUsers = state => state.userReducer.users
export const getUserOrg = state => state.userReducer.org
export const getUserError = state => state.userReducer.error
export const getUserLoader = state => state.userReducer.loader
export const getOrgLoader = state => state.userReducer.orgloader

export const setUser = (user) => ({type: SET_USER, user})
export const setUserStat = (userstat) => ({type: SET_USERSTAT, userstat})
export const setPurchases = (purchases) => ({type: SET_PURCHASES, purchases})
export const setUsers = (users) => ({type: SET_USERS, users})
export const setUserOrg = (org) => ({type: SET_ORG, org})
export const setUserLoader = (loader) => ({type: SET_LOADER, loader})
export const setOrgLoader = (orgloader) => ({type: SET_ORGLOADER, orgloader})
export const setUserError = (error) => ({type: SET_ERROR, error})


export const loadUserStat = (id, sattr = 'all') => async dispatch => {
    const response = await EQPAPI.getUserStat(id, sattr)
    if (response.status === 200 ) {
        const result = response.data
        dispatch(setUserStat(result))
    }
}

export const loadUser = (id) => async dispatch => {

    dispatch(setUserLoader(true))
    try {
        if (id != 'new') {
            const response1 = await EQPAPI.getPurchases(id)
            if (response1.status === 200 ) {
                const result1 = response1.data
                dispatch(setPurchases(result1))
            }        
        }
        const response = await EQPAPI.getUser(id)
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setUser(result))
            dispatch(setUserLoader(false))
        }
    }
    catch (error) {
        dispatch(setUserLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}



export const loadUserOrg = (id) => async dispatch => {

    dispatch(setOrgLoader(true))
    try {
        const response = await EQPAPI.getUserOrg(id)
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setUserOrg(result))
            dispatch(setOrgLoader(false))
        }
    }
    catch (error) {
        dispatch(setOrgLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}

export const loadUserOrgInfo = (org) => async dispatch => {

    dispatch(setOrgLoader(true))
    try {
        const response = await EQPAPI.getUserOrgInfo(org)
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setUserOrg(result))
            dispatch(setOrgLoader(false))
        }
    }
    catch (error) {
        dispatch(setOrgLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}



export const newUser = (user, org) => async dispatch => {

    let error = {iserror: false}

    if ((user?.username?.toString() == null) ||(user?.username.toString() == ''))  error = {...error,  userUSERNAME:   {text: 'Не указано имя пользлователя (эл. почта)'}, iserror: true}
    if ((user?.first_name?.toString() == null) ||(user?.first_name.toString() == ''))  error = {...error,  userFIRST_NAME:   {text: 'Не указано имя'}, iserror: true}
    if ((user?.last_name?.toString() == null) ||(user?.last_name.toString() == ''))  error = {...error,  userLAST_NAME:   {text: 'Не указана фамилия'}, iserror: true}

    if (org != null) {

        if ((org?.inn?.toString() == null) ||(org?.inn.toString() == ''))  error = {...error,  inn:   {text: 'Не указан ИНН организации'}, iserror: true}
        if (!org?.ip)
        if ((org?.kpp?.toString() == null) ||(org?.kpp.toString() == ''))  error = {...error,  kpp:   {text: 'Не указан КПП организации'}, iserror: true}
    }
    
    if (error.iserror )
    dispatch(setUserError(error))
    else
    try {
      dispatch(setUserLoader(true))
        const response = await EQPAPI.newUser(user)
        
        if (response.status === 200 && response.data.length !== 0) {
            
            const result = response.data
            dispatch(setUser(result))
            if (org != null) {
                org.user = response?.data?.id
                const response2 = await EQPAPI.newUserOrg(org);
                if (response2.status === 200 && response2.data.length !== 0) {
                    
                    dispatch(setUserOrg(response2.data));
                }              
            } 
            dispatch(loadAllUsers())
            dispatch(setUserLoader(false))
            dispatch(setUserError(null))
        }

      
    }
    catch (error) {
        let errorout = {iserror: false}
        dispatch(setUserLoader(false))
        if (error?.response?.data?.username)
        errorout = {...errorout,  userUSERNAME:   {text: error?.response?.data?.username}, iserror: true}
        if (error?.response?.data?.email)
        errorout = {...errorout,  userUSERNAME:   {text: error?.response?.data?.email}, iserror: true}

        dispatch(setUserError(errorout))
    }     
}

export const updateUser = (user, org) => async dispatch => {

    let error = {iserror: false}

    if ((user?.username?.toString() == null) ||(user?.username.toString() == ''))  error = {...error,  userUSERNAME:   {text: 'Не указано имя пользлователя (эл. почта)'}, iserror: true}
    if ((user?.first_name?.toString() == null) ||(user?.first_name.toString() == ''))  error = {...error,  userFIRST_NAME:   {text: 'Не указано имя'}, iserror: true}
    if ((user?.last_name?.toString() == null) ||(user?.last_name.toString() == ''))  error = {...error,  userLAST_NAME:   {text: 'Не указана фамилия'}, iserror: true}

    if (org != null) {

        if ((org?.inn?.toString() == null) ||(org?.inn.toString() == ''))  error = {...error,  inn:   {text: 'Не указан ИНН организации'}, iserror: true}
        if (!org?.ip)
        if ((org?.kpp?.toString() == null) ||(org?.kpp.toString() == ''))  error = {...error,  kpp:   {text: 'Не указан КПП организации'}, iserror: true}
    }
    
    if (error.iserror )
    dispatch(setUserError(error))
    else
    try {
      dispatch(setUserLoader(true))
        const response = await EQPAPI.updateUser(user)
        
        if (response.status === 200 && response.data.length !== 0) {
            
            const result = response.data
            dispatch(setUser(result))
            if (org != null) {
                org.user = response?.data?.id
                const response2 = await EQPAPI.updateUserOrg(org);
                if (response2.status === 200 && response2.data.length !== 0) {
                    
                    dispatch(setUserOrg(response2.data));
                }              
            } 
            dispatch(loadAllUsers())
            dispatch(setUserLoader(false))
            dispatch(setUserError(null))
        }

      
    }
    catch (error) {
        let errorout = {iserror: false}
        dispatch(setUserLoader(false))
        if (error?.response?.data?.username)
        errorout = {...errorout,  userUSERNAME:   {text: error?.response?.data?.username}, iserror: true}
        if (error?.response?.data?.email)
        errorout = {...errorout,  userUSERNAME:   {text: error?.response?.data?.email}, iserror: true}

        dispatch(setUserError(errorout))
    }     
}



export const loadUserEdit = (id) => async dispatch => {
    dispatch(setUserLoader(true))
    try {
        const response = await EQPAPI.getUserEdit(id)
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setUser(result))
            dispatch(setUserLoader(false))
        }
    }
    catch (error) {
        dispatch(setUserLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}

export const loadAllUsers = () => async dispatch => {
    dispatch(setUserLoader(true))
    try {
        const response = await EQPAPI.getAllUsers()
        if (response.status === 200 && response.data.length !== 0) {
            const result = response.data
            dispatch(setUsers(result))
            dispatch(setUserLoader(false))
        }
    }
    catch (error) {
        dispatch(setUserLoader(false))
        error = {...error, userCode: {text: 'Что-то пошло не так...'}}
        error = {...error, iserror: true}
    }     
}