import {applyMiddleware, combineReducers, createStore} from "redux";
import thunkMiddleware from "redux-thunk";

import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import {composeWithDevTools} from 'redux-devtools-extension';
import {loginReducer} from "./reducers/login-reducer";
import {userProfile} from "./reducers/user-profile-reducer";
import {notificationReducer} from "./reducers/notification-reducer";
import {promoReducer} from "./reducers/promo-reducer";
import {registrationReducer} from "./reducers/registration-reducer";
import {activationReducer} from "./reducers/activation-reducer";
import {recoveryReducer} from "./reducers/recovery-reducer";
import {userProfileEdit} from "./reducers/user-profile-personal-reducer";
import {searchReducer} from "./reducers/search-reducer";
import {okReducer} from "./reducers/ok-reducer";
import {regionsReducer} from "./reducers/regions-reducer";
import {EQReducer} from "./reducers/eq-reducer"
import {ASReducer} from "./reducers/advancedsearch-reducer"
import {firmReducer} from "./reducers/firm-reducer"
import {picReducer} from "./reducers/pic-reducer"
import {ivReducer} from "./reducers/iv-reducer"
import {specReducer} from "./reducers/spec-reducer"
import {tariffReducer} from "./reducers/tariff-reducer"
import {userReducer} from "./reducers/user-reducer"
import {parseReducer} from "./reducers/parse-reducer"
import { keeperReducer } from "./reducers/keeper-reducer";
import { purchasesReducer } from "./reducers/purchases-reducer";
import { feedbackReducer } from "./reducers/feedback-reducer";

const persistConfig = {
    key: 'appState',
    storage: storage,
    whitelist: ['userProfile']
}

const allReducers = combineReducers({
    loginReducer,
    activationReducer,
    notificationReducer,
    userProfile,
    promoReducer,
    registrationReducer,
    recoveryReducer,
    userProfileEdit,
    searchReducer,
    okReducer,
    EQReducer,
    ASReducer,
    regionsReducer,
    firmReducer,
    picReducer,
    ivReducer,
    specReducer,
    tariffReducer,
    userReducer,
    parseReducer,
    keeperReducer,
    purchasesReducer,
    feedbackReducer
    },
)

const RESET_APP = 'RESET_APP'

const rootReducer = (state, action) => {
    if (action.type === RESET_APP) {
        state = undefined;
    }

    return allReducers(state, action);
}


export const store = createStore(
    persistReducer(
        persistConfig,
        rootReducer
    ), composeWithDevTools(
        applyMiddleware(thunkMiddleware)
    )
)

export const resetStore = () => store.dispatch({type: RESET_APP})

export const persistor = persistStore(store)