import { sendFeedback } from "../redux/reducers/feedback-reducer";
import {instance} from "./axiosInstance";


export const AuthAPI = {
    login: async (username, password) => {
        let response = await instance.post('/token/obtain/', {username, password})
        instance.defaults.headers['Authorization'] = "JWT " + response.data.access;
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
        return response;
    },
    recoveryCheckLogin: async (username) => {
        return await instance.post('/user/recovery/', {username})
    },
    recoveryCheckCode: async (code) => {
        return await instance.post('/user/recovery-checkcode/', {code})
    },    
    CheckEmailView: async (email) => {
        return await instance.post('user/check-email/', {email})
    },    
    CheckINNView: async (inn, kpp) => {
        return await instance.post('user/check-inn/', {inn, kpp})
    },    
    recoveryChangePassword: async (code, password) => {
        return await instance.post('/user/recovery-changepassword/', {code, password})
    },
    logout: async () => {
        return await instance.post('/token/blacklist/', {
            "refresh_token": localStorage.getItem("refresh_token")
        });
    },
    createUser: ({
        username,
        password,
        role,
        first_name,
        last_name,
        mid_name,
        inn            
         }) =>
            instance.post('/user/create/', {
                username,
                password,
                role,
                first_name,
                last_name,
                mid_name,
                inn            
                }),    
    checkCode: (values) => instance.post('/user/check-code/', values),                
}

export const UserAPI = {
    getUsers: (isMobile) => instance.get(`/user/get-user/?is_mobile=${isMobile}`),
    createIndividualUser: async ({
                username,
                email,
                password,
                role,
                first_name,
                last_name,
                mid_name,
                is_activated       
                 }) => {
                    return await instance.post('/user/createindividual/', {
            username,
            email,
            password,
            role,
            first_name,
            last_name,
            mid_name,
            is_activated  
            })

        },
    createLegalUser: async ({
                username,
                email,
                password,
                role,
                first_name,
                last_name,
                mid_name,
                is_activated,
                org   
                 }) => {
                    return await instance.post('/user/createlegal/', {
            username,
            email,
            password,
            role,
            first_name,
            last_name,
            mid_name,
            is_activated,
            org
            })

        },        
    patchUser: (id, data) => instance.patch(`/user/update-profile/${id}/`, data),
    
}

export const ProfileAPI = {
    getProfile: () => instance.get('user/me/'),
    getPurchases: () => instance.get('user/purchases/'),
    getOrg: () => instance.get('user/org/'),
    getPurchaseOrg: (id) => instance.get(`user/purchaseorg/${id}`),
    savePersonalData: (values) => instance.post('/user/save-personal-data/', values),
    updateProfile: (values) => instance.patch(`/user/update-self-profile/${values.id}/`, values),
    saveSubscriptions: (values) => instance.post('/user/save-subscriptions/', values),
    saveProtect: (values) => instance.post('/user/save-protect/', values),
    updatePassword: (values) => instance.put(`/user/change-password/`, values),
    activateProfile: (code, id) => instance.put(`/user/activate-profile/?code=${code}&id=${id}`),
    sendNoteAction: (id) => instance.put(`/user/send-activation-code/?id=${id}`)
}

export const KEEPERAPI = {
    getOrder: (id) => { return instance.get(`keeper/getorder/${id}/`)},
    createOrder: (id) => { return instance.get(`keeper/createorder/${id}/`)},
    loadOrder: (id) => { return instance.get(`keeper/order/${id}/`)},

}


export const EQPAPI = {
    getPromo: () => { return instance.get(`eqp/promo/`)},
    getOkList: (owner) => { return instance.get(`eqp/ok/?owner=${owner}`)},
    getPicList: () => { return instance.get(`eqp/pics/`)},
    getRegionsList: () => { return instance.get(`eqp/regions/`)},
    setBookmark: (eqid) => {return instance.post(`bookmark/${eqid}/`)},
    setCompare: (eqid) => {return instance.post(`compare/${eqid}/`)},
    getSearch: (values) => { 

        if(values?.ok)
        return ((values?.query)&&(!values?.chap))
                    ?instance.get(`eqp/search/basic/?query=`+values.query+`&p=`+values.p+`&ok=`+values.ok+genParams(values.params))
                    :(values?.chap)
                        ?instance.get(`eqp/search/basic/?p=`+values.p+`&ok=`+values.ok+genParams(values.params)+"&chap="+values.chap)
                        :instance.get(`eqp/search/basic/?p=`+values.p+`&ok=`+values.ok+`&eq=`+values.eq+genParams(values.params))
        else
        return instance.get(`eqp/search/basic/?query=`+values.query+`&p=`+values.p+genParams(values.params))
    },
    getASearch: (values) => { 
        return instance.get(`eqp/search/advanced/?aname=${(values.aname)?values.aname:''}&p=${values.p}&ashifr=${(values.ashifr)?values.ashifr:''}&ok=${values.ok}&childok=${values.childok}&acompany=${values.acompany}&aregion=${values.aregion}${genParams(values.params)}`)
    },
    getFSearch: (values) => { 
        return instance.get(`eqp/search/favorite/?p=${values.p}&ok=${values.ok}${genParams(values.params)}`)
    },
    getCSearch: (values) => { 
        return instance.get(`eqp/search/compare/?p=${values.p}&ok=${values.ok}${genParams(values.params)}`)
    },

    getOk: (values) => { return instance.post(`eqp/search-ok/`,values)},
    getOkBTP: (okid) => { return instance.get(`eqp/ok-btp/?okid=${okid}`)},

    getOperatorEQPSearch: (values) => instance.post('/eqp/operator-eqp-search/', values),

    getBTP: (id, query) => { return (query) ? instance.get(`eqp/search-btp/?id=${id}&query=${query}`): instance.get(`eqp/search-btp/?id=${id}`)},
    getEQ: (id) => { return instance.get(`eq/${id}/`)},
    getEIList: () => { return instance.get(`ei/`)},
    getEQEdit: (id) => { return instance.get(`eqedit/${id}/`)},
    getEQFromUrl: (url) => { return instance.post(`eqedit/new/`, url)},
    setEQEdit: (id, values) =>  (id != null)?instance.post(`eqedit/${id}/`,values):instance.post(`eqedit/new/`,values),
    getFirm: (id) => { return instance.get(`firm/${id}/`)},
    getFirmSync: (firm) => instance.post('/firmsync/', firm),
    updateFirm: (id, firm) => instance.put(`firmedit/${id}/`, firm),
    newFirm: (firm) => instance.post(`firmedit/new/`, firm),

    newOk: (ok) => instance.post(`okedit/new/`, ok),
    getOkItem: (ok) => instance.get(`okedit/${ok.id}/`),    
    updateOk: (ok) => instance.put(`okedit/${ok.ID}/`, ok),
    deleteOk: (ok) => instance.delete(`okedit/${ok.ID}/`, ok),
    newRegion: (region) => instance.post(`regionedit/new/`, region),
    updateRegion: (region) => instance.put(`regionedit/${region.ID}/`, region),
    deleteRegion: (region) => instance.post(`regionedit/${region.ID}/`, region),
    getGeo: (id) => { return instance.get(`geo/${id}/`)},
    getFirmEdit: (id) => { return instance.get(`firmedit/${id}/`)},
    getFirms: () => { return instance.get(`eqp/firms/`)},
    getIV: () => { return instance.get(`/ivedit/`)},
    newIV: (iv) => instance.post(`ivedit/new/`, iv),
    newEI: (ei) => instance.post(`eiedit/new/`, ei),
    updateEI: (ei) => instance.put(`eiedit/${ei.ID}/`, ei),
    deleteEI: (ei) => instance.post(`eiedit/${ei.ID}/`, ei),
    updateIV: (iv) => instance.put(`ivedit/${iv.ID}/`, iv),
    deleteIV: (iv) => instance.post(`ivedit/${iv.ID}/`, iv),
    getEIEdit: (id) => { return instance.get(`/eiedit/${id}/`)},
    getAllFirms: () => { return instance.get(`eqp/allfirms/`)},


    getPurchasesEdit: () => { return instance.get(`/purchasesedit/`)},
    getPurchase: (id) => { return instance.get(`/purchasesedit/${id}/`)},
    uploadPurchaseInvoiceFile: (id, invoice_file) => instance.put(`/purchasesedit/${id}/`, invoice_file),
    setPurchaseStatusPaid: (id, setpaid) => instance.put(`/purchasesedit/${id}/`, setpaid),



    getTariffs: () => { return instance.get(`/tariffs/`)},
    getTariffsEdit: () => { return instance.get(`/tariffedit/`)},
    newTariff: (tariff) => instance.post(`tariffedit/new/`, tariff),
    getTariff: (id) => { return instance.get(`/tariffedit/${id}/`)},
    updateTariff: (tariff) => instance.put(`tariffedit/${tariff?.ID}/`, tariff),

    getSpecs: () => { return instance.get(`/specedit/`)},
    getPrm: () => { return instance.get(`/specedit/prm/`)},

    getPic: (id) => { return instance.get(`/picedit/${id}/`)},
    newPic: (pic) => instance.post(`picedit/new/`, pic),
    updatePic: (pic) => instance.put(`picedit/${pic?.ID}/`, pic),
    deletePic: (pic) => instance.delete(`picedit/${pic.ID}/`),

    getSpec: (id) => { return instance.get(`/specedit/${id}/`)},
    updateSpec: (id, spec) => instance.put(`specedit/${id}/`, spec),
    newSpec: (spec) => instance.post(`specedit/new/`, spec),
    deleteSpec: (spec) => instance.delete(`specedit/${spec.ID}/`),
    
    getAllUsers: () => { return instance.get(`/useredit/`)},
    getPurchases: (id) => { return instance.get(`/usertariff/${id}/`)},
    getUser: (id) => { return instance.get(`/useredit/${id}/`)},
    
    newUser: (user) => instance.post(`/useredit/new/`, user),
    updateUser: (user) => instance.put(`/useredit/${user.id}/`, user),
    getUserOrg: (id) => { return instance.get(`/userorgedit/${id}/`)},
    newUserOrg: (org) => instance.post(`/userorgedit/new/`, org),
    updateUserOrg: (org) => instance.put(`/userorgedit/`, org),

    getUserOrgInfo: (org) => { return instance.post(`/userorgeditinfo/`, org)},


    getParseUrl: (parseItem) => instance.post(`/parse/study/`, parseItem),
    setParseSettings: (parseItem) => instance.post(`/parse/savesettings/`, {parsesettings:parseItem}),

    sendFeedback: (form) => { return instance.post(`/sendfeedback/`, form)},

    getUserStat: (id, sattr) => { return instance.get(`/stat/log/${sattr}/user/${id}/`)},
}
    
const genParams = params => {
    //console.log(params);
    let out = ''
    if(params != null)
    params.forEach( function callback (paramsItem) {
        Object.entries(paramsItem).forEach(entry => {

            const [key, value] = entry;
            if ((key != 'filters')&&(value!="")) {                
                out+='&'+key+'='+value
            }
            else {
                let btpCount = 0;
                let ei= [], prm= [], minval= [], maxval = [], active = []
                if (paramsItem.filters != null)
                value.forEach( function callback (BTPItem) {
                    active.push(BTPItem.active)
                    Object.entries(BTPItem.forks[BTPItem.SELECTED]).forEach(fork => {
                        const [forkKey, forkValue] = fork;
                        
                        if (forkKey == 'ei')
                            ei.push(forkValue)
                        if (forkKey == 'prm')
                            prm.push(forkValue)
                        if (forkKey == 'minval')
                            minval.push(forkValue)
                        if (forkKey == 'maxval')
                            maxval.push(forkValue)                                                                                    
                        //

                    })
                    btpCount++
                })
                if (ei.length > 0)
                out+='&filters=true&ei='+ei+'&prm='+prm+'&minval='+minval+'&maxval='+maxval+'&active='+active
            }
            
        })
    })
    return out
}