import React, { createRef, useState, useEffect, useCallback, useRef } from "react";
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {getLoggedIn} from "../../redux/reducers/login-reducer";
import {getUserStat, setUserStat, loadUserStat, getOrgLoader, getPurchases, getUser, getUserError, getUserLoader, getUserOrg, getUsers, loadAllUsers, loadUser, loadUserOrg, loadUserOrgInfo, newUser, setUser, setUserOrg, updateUser} from "../../redux/reducers/user-reducer";
import  Loader  from '../Loader/Loader';
import {GridCellExpand} from "../GridCellExpand/GridCellExpand.jsx";
import { UsersTable } from "../UsersTable/UsersTable";
import { UserStatTable } from "../UserStatTable/UserStatTable";
import { PurchasesTable } from "../PurchasesTable/PurchasesTable";
import {useQuery} from "../../hooks/useQuery";
import {useRedirect} from "../../hooks/useRedirect";
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import BarChartIcon from '@mui/icons-material/BarChart';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const useStyles = makeStyles((theme) => ({

  
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '',
        padding: '5px'
    },
    button: {
      color: '#fff',
      backgroundColor: '#054C74',
      '&:hover': {
          backgroundColor: '#E6E6E6',
          color: '#1A1A1A',
      },        
  },     

}))


export default function Users() {

    const query = useQuery()
    const id = query.get('id')
    const {replace} = useRedirect()

    const classes = useStyles()
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(getLoggedIn)
    const  users  = useSelector(getUsers);    
    const userItem = useSelector(getUser)
    const userStat = useSelector(getUserStat)
    const orgItem = useSelector(getUserOrg)
    const userLoader = useSelector(getUserLoader)
    const orgLoader = useSelector(getOrgLoader)
    const purchases = useSelector(getPurchases)
    const error = useSelector(getUserError)
    
    const [ip, setIp] = useState(false);
    const [userStatDialog, setUserStatDialog] = useState(false);
 
    const roles = {
      operator: 'Администратор',
      individual: 'Физическое лицо',
      entity: 'Юридическое лицо',
    }
    const types = {
      individual: 'Физическое лицо',
      entity: 'Юридическое лицо',
    }    

    useEffect(() => {
      if((users == null)&&(!userLoader))
      dispatch(loadAllUsers());
      if ((id) &&(!userItem)&&(users)) {
        dispatch(loadUser(id))
    }      
    }, [users,id]);        


  useEffect(() => {
  if ((id != null) &&(id != 'new'))
  if (id == null)
    dispatch(setUser(null))
  }, [id]);        

  useEffect(() => {
    if (userItem?.ID != null)
        replace('/operator/?tab=Users&id='+userItem?.ID)

    if((userItem)&&(!orgItem)&&(userItem?.role == 'entity'))
        dispatch(loadUserOrg(id))

    }, [userItem]);   

    useEffect(() => {
      
      if ((!orgLoader)&&(orgItem)) {
        if ((orgItem?.ip)&&(orgItem?.kpp != ''))
          dispatch(setUserOrg({... orgItem, ip:false}))
        if ((!orgItem?.ip)&&(orgItem?.kpp == ''))
          dispatch(setUserOrg({... orgItem, ip:true}))        
      }
  
      }, [orgLoader]);   
  

  const setUserValue = (name, value) => {
    if ((name == 'role') && (value != 'entity'))
      dispatch(setUserOrg(null))
    let userItemCopy = {... userItem}
    userItemCopy[name] = value
    dispatch(setUser(userItemCopy))
  }

  const setOrgValue = (name, value) => {
    let orgItemCopy = {... orgItem}
    orgItemCopy[name] = value
    dispatch(setUserOrg(orgItemCopy))
  } 
  
  const changeIp = () => {
    if (orgItem.ip == false) {
      dispatch(setUserOrg({... orgItem, kpp:'', ip:true}))

    }
    else 
    dispatch(setUserOrg({... orgItem, ip:false}))

  }

  const closeUserEdit = () => {
    dispatch(setUser(null))
    dispatch(setUserOrg(null))
    replace('/operator/?tab=Users')
  }

  const getLoadUserStat = (id, sattr) => {
    
    dispatch(loadUserStat(id, sattr))
    setUserStatDialog(true)
  }
  
    
      const RenderDescription = (params) => {
        return (
          <GridCellExpand
              value={params.value ? params.value.toString() : ''}
              eqid = {params.id}
              width={params.colDef.computedWidth}
              link = {'/operator/?tab=Users&id='+params.id}
              
             // clickHandle = {props.action}
          />
        )
      }        

      const RenderUserStatLink = (params) => {
        console.log(params);
        
        return (
          <GridCellExpand
              value={params.value ? params.value.toString() : ''}
              href = {params.row?.link}
              width={params.colDef.computedWidth}
              
             // clickHandle = {props.action}
          />
        )
      }  

      const genColumns = () => {
        let compareColumns = [
          { 
            field: "stat", 
            headerName: ".", 
            renderHeader: (params) => (<BarChartIcon/>),
            minWidth: 15, 
            renderCell: (params) => (<BarChartIcon onClick={()=>getLoadUserStat(params.row.id, 'all')} style={{cursor:'pointer'}} />),
            sortable: false
//            renderCell: RenderDescription
            },           
            { 
            field: "id", 
            headerName: "ID", 
            minWidth: 15, 

//            renderCell: RenderDescription
            }, 
            { 
            field: "fio", 
            headerName: "Ф.И.О.", 
            minWidth: 300, flex: 1, 
            renderCell: RenderDescription
            }, 
            { 
              field: "email", 
              headerName: "Эл. почта", 
              width: 100, flex: 1, 
              renderCell:RenderDescription, 
            }, 
            { 
                field: "type", 
                headerName: "Тип", 
                width: 100, flex: 1, 
                renderCell:RenderDescription, 
            },                  
            { 
              field: "is_active", 
              headerName: "Активный", 
              width: 100, flex: 1, 
              renderCell:RenderDescription, 
            },             
            ]

        return compareColumns
    }      


    const genUserStatColumns = () => {
      let userStatColumns = [
          { 
          field: "querydate", 
          headerName: "Дата", 
          minWidth: 100, flex: 1, 
          renderCell: RenderUserStatLink
          }, 
          { 
            field: "sattr", 
            headerName: "Тип", 
            width: 100, flex: 1, 
            renderCell:RenderUserStatLink, 
          }, 
          { 
              field: "name", 
              headerName: "Запрос", 
              width: 100, flex: 1, 
              renderCell:RenderUserStatLink, 
          },                  
          ]

      return userStatColumns
  }   

    const genPurchasesColumns = () => {
      let compareColumns = [
          { 
          field: "id", 
          headerName: "ID", 
          minWidth: 15, 
          
//            renderCell: RenderDescription
          }, 
          { 
          field: "tariff", 
          headerName: "Тариф", 
          minWidth: 300, flex: 1, 
          
          }, 
          { 
            field: "datebuy", 
            headerName: "Дата покупки", 
            width: 300, flex: 1, 
            
          }, 
          { 
              field: "datestop", 
              headerName: "Дата окончания", 
              width: 300, flex: 1, 
             
          },             
          ]

      return compareColumns
  }      

  const genPurchasesRows = () => {
    let rowData = []
    purchases?.forEach( function callback (purchItem) {
        let tmpRow = {
          id:purchItem.id,
          tariff: types[purchItem.tariffinfo.TYP]+' '+purchItem.tariffinfo.DURATION+' дней',
          datebuy:purchItem.datebuy, 
          datestop:purchItem.datestop, 
        }
        rowData.push(tmpRow)
    })
    return rowData

}  
    const genRows = () => {
        let rowData = []
        users?.forEach( function callback (userItem) {
            let tmpRow = {
              id:userItem.id,
              fio: userItem.last_name+' '+userItem.first_name+' '+userItem.mid_name,
              first_name:userItem.first_name, 
              last_name:userItem.last_name, 
              mid_name:userItem.mid_name, 
              email:userItem.email, 
              is_active:(userItem.is_active)?'Да':'Нет', 
              date_joined:userItem.date_joined,
              type:roles[userItem.role]
            }
            rowData.push(tmpRow)
        })
        return rowData

    }  
  
    const genUserStatRows = () => {
      let rowData = []
      userStat?.forEach( function callback (statItem) {
          let aname = ''
          let alink = '/eqpsearch/advanced/'
          let anamefirst = true
          if (statItem.aname !='') {
            if (!anamefirst) {
              alink +='&'
              aname += ', '             
            } else
              alink +='?'
            aname += statItem.aname
            alink += 'aname='+statItem.aname
            anamefirst = false
          }
          if (statItem.ashifr !='') {
             if (!anamefirst){
                alink +='&'
                aname += ', '
              } else
              alink +='?'
             alink += 'ashifr='+statItem.ashifr

             aname += statItem?.ashifr
             anamefirst = false
          }
          if (statItem.aok) {
            if (!anamefirst){
              alink +='&'
              aname += ', '
            } else
              alink +='?'
            aname += statItem?.okinfo?.NAME
            alink += 'aok='+statItem.aok+'&aokname='+statItem?.okinfo?.NAME
            anamefirst = false
          }else {
            if (!anamefirst){
              alink +='&'
            } else
              alink +='?'
            alink += 'aok=any&aokname=Любой'
          }

          if (statItem.acompany) {
            if (!anamefirst){
              alink +='&'
              aname += ', '
            } else
              alink +='?'
            
            aname += statItem?.companyinfo?.NAME
            alink += 'acompany='+statItem.acompany
            anamefirst = false
          } else {
            if (!anamefirst){
              alink +='&'
            } else
              alink +='?'
            alink += 'acompany=any'
          }



          if (statItem.aregion) {
            if (!anamefirst){
              alink +='&'
              aname += ', '
            } else
              alink +='?'
            aname += statItem?.regioninfo?.NAME
            alink += 'aregion='+statItem.aregion
            anamefirst = false
          } else {
            if (!anamefirst){
              alink +='&'
            } else
              alink +='?'
            alink += 'aregion=any'
          }


          let tmpRow = {
            id: statItem.id,
            sattr:(statItem.sattr == 'card')?'Карточка':(statItem.sattr == 'simple')?'Простой':(statItem.sattr == 'advanced')?'Расширенный':null,
            name:(statItem.eq)?statItem.eqinfo.NAME:(statItem.qtext!='')?statItem.qtext:aname,
            querydate:statItem.querydate,
            link:(statItem.sattr == 'card')?`/eq/${statItem.eqinfo.ID}`:(statItem.sattr == 'simple')?`/eqpsearch/?query=${statItem.qtext}`:(statItem.sattr == 'advanced')?alink:''
          }
          rowData.push(tmpRow)
      })
      
      return rowData

  }      
    const closeDialogUserStat = () => {
      setUserStatDialog(false);
      dispatch(setUserStat(null))
    };

   
    return (
        ((id)&&(userItem)&&(!userLoader))?
        <div style={{ margin:'auto', padding:'20px'}}>

          <div style={{float:'left'}}>
            {(userItem?.id)?
              <div>     
                  <div className={classes.headitemname} style={{paddingBottom:'10px'}}>Пользователь изменен: {userItem?.date_joined}</div>
              </div>          
            :<div>     
                  <div className={classes.headitemname} style={{paddingBottom:'10px'}}>Новый пользователь</div>
              </div>} 
            <div  style={{width:"-webkit-fill-available"}} >
              <Checkbox 
                checked={userItem.is_active} 
                onClick={() =>setUserValue('is_active', !userItem.is_active)} 
                color="#054C74"/>Активный
            </div>    
            <div>
              <TextField
                size="small"
                style={{ width:'300px'}}
                className={classes.orderMenu}
                margin="dense"
                padding="none"
                id="standard-userUSERNAME"
                label="Тип пользователя"
                select
                value={userItem.role}
                onChange={(e)=> setUserValue('role', e.target.value)}
              >
                <MenuItem key="individual" value="individual">Физическое лицо</MenuItem>
                <MenuItem key="entity" value="entity">Юридическое лицо</MenuItem>
                <MenuItem key="supplier" value="supplier">Производитель</MenuItem>
                <MenuItem key="operator" value="operator">Администратор</MenuItem>
                
                
              </TextField>          
            </div>                    
            <div>
              <TextField
                size="small"
                style={{ width:'300px'}}
                className={classes.orderMenu}
                margin="dense"
                padding="none"
                id="standard-userUSERNAME"
                label="Имя пользлователя (эл. почта)"
                value={userItem.username}
                error={!!error?.userUSERNAME}
                helperText={error?.userUSERNAME?.text}                                                   
                onChange={(e)=> setUserValue('username', e.target.value)}
              ></TextField>          
            </div>                     
            <div>
              <TextField
                size="small"
                style={{ width:'300px'}}
                className={classes.orderMenu}
                margin="dense"
                padding="none"
                id="standard-userFIRST_NAME"
                label="Имя"
                value={userItem.first_name}
                error={!!error?.userFIRST_NAME}
                helperText={error?.userFIRST_NAME?.text}                                                   
                onChange={(e)=> setUserValue('first_name', e.target.value)}
              ></TextField>          
            </div>
            <div>
              <TextField
                size="small"
                style={{ width:'300px'}}
                className={classes.orderMenu}
                margin="dense"
                padding="none"
                id="standard-userLAST_NAME"
                label="Фамилия"
                value={userItem.last_name}
                error={!!error?.userLAST_NAME}
                helperText={error?.userLAST_NAME?.text}                                                   
                onChange={(e)=> setUserValue('last_name', e.target.value)}
              ></TextField>          
            </div>
            <div>
              <TextField
                size="small"
                style={{ width:'300px'}}
                className={classes.orderMenu}
                margin="dense"
                padding="none"
                id="standard-userMID_NAME"
                label="Отчество"
                value={userItem.mid_name}
                onChange={(e)=> setUserValue('mid_name', e.target.value)}
              ></TextField>          
            </div>
            <div>
              <TextField
                size="small"
                style={{ width:'300px'}}
                className={classes.orderMenu}
                margin="dense"
                padding="none"
                id="standard-userPHONE"
                label="Телефон"
                value={userItem.phone_number}
                onChange={(e)=> setUserValue('phone_number', e.target.value)}
              ></TextField>          
            </div>  

            <div  style={{width:"-webkit-fill-available"}} >
              <Checkbox 
                checked={userItem.news} 
                onClick={() =>setUserValue('is_active', !userItem.news)} 
                color="#054C74"/>Подписка на новости сайта
            </div>
            <div  style={{width:"-webkit-fill-available"}} >
              <Checkbox 
                checked={userItem.alerts} 
                onClick={() =>setUserValue('is_active', !userItem.alerts)} 
                color="#054C74"/>Подписка на оповещения о событиях
            </div>          
          </div>
          {(orgLoader)?<div style={{marginLeft:'30px', float: 'left'}}><Loader/></div>
          :((userItem?.role == 'entity') || (userItem?.role == 'supplier') )?
          <div style={{float:'left', padding:'5px 0 0 20px'}}>
            <div>Сведения о юридическом лице</div>
            <div >
            <TextField
                margin='dense'
                padding='none'
                size="small"
                id={'outlined-basic'}
                label={'ИНН'}
                fullWidth={true}
                autoComplete={'inn'}
                variant={'outlined'}
                type={'string'}
                error={!!error?.inn}
                helperText={error?.inn?.text}
                value={orgItem?.inn}
                onChange={(e)=> setOrgValue('inn', e.target.value)}
                />
            </div>
            {(!orgItem?.ip)?
            <div >
                <TextField
                margin='dense'
                padding='none'
                size="small"
                id={'outlined-basic'}
                label={'КПП'}
                fullWidth={true}
                autoComplete={'kpp'}
                variant={'outlined'}
                type={'string'}
                error={!!error?.kpp}
                helperText={error?.kpp?.text}
                value={orgItem?.kpp}
                onChange={(e)=> setOrgValue('kpp', e.target.value)}
                />
            </div>:null
            
            }
          <div>
          <Checkbox 
          checked={orgItem?.ip} 
          onClick={() => changeIp()} 
          color="#054C74"/>Нет КПП
          </div>   
          
          <Grid container spacing={2} justifyContent="center" style={{padding:'20px'}}>
                        <Grid item>
                            <div >
                            <Button variant="contained" 
                              //disabled={stepLoader}  
                              size={'sdmall'} 
                              onClick={()=>dispatch(loadUserOrgInfo(orgItem))}  
                              className={classes.button}>
                                Синхронизировать
                            </Button>
                            </div>
                            
                        </Grid>
          </Grid>        
          {
          (orgItem?.actualitydate != null)?
          <div>
            <div style={{width:'min-content'}}>{orgItem?.shortwithopf}</div>  
            <div style={{paddingTop:'10px'}}>Статус: {(orgItem?.state)?<span style={{color:'darkgreen'}}>Действует</span>:<span style={{color:'darkgred'}}>Не действует</span>}</div>
            <div style={{paddingTop:'10px'}}>Дата регистрации: {orgItem?.registrationdate}</div>  
            <div style={{paddingTop:'10px', width:'min-content'}}>{orgItem?.addressline}</div>  
          </div>
          :null

          }

          </div>
          :null
          }
        <div style={{display:'inline-block', width:'-webkit-fill-available'}}>
         <PurchasesTable style={{height:'auto'}} data={genPurchasesRows()} columns={genPurchasesColumns()} />
         </div>
        <Grid container spacing={2} justifyContent="center" style={{padding:"20px 0"}}>
                    <Grid item>
                        <Button variant="contained" 
                            //disabled={submitLoader} ]
                            onClick={() => {


                                //setFirmLoaded(false)
                                //dispatch(setGeo(null))                        
                                
                                if (id == 'new') 
                                    dispatch(newUser(userItem, orgItem))

                                else if (id != null)  
                                  dispatch(updateUser(userItem, orgItem))
                                    
                               
                                    
                            }}  
                            style={{color: '#fff', backgroundColor: 'darkgreen', marginBottom:'30px'}}
                            size={'large'} 
                        >
                            Сохранить
                        </Button>
                    </Grid>


                    <Grid item>
                        <Button 
                        style={{color: '#fff', backgroundColor: '#054C74', marginBottom:'30px'}}
                        size={'large'} 
                        onClick={()=>closeUserEdit()}
                        >Закрыть</Button>                    
                    </Grid>                    
            </Grid>           
        </div>
        :((users)&&(!id)&&(!userLoader))?
        <div >
          <Dialog
          className={classes.mainDialog}
            open={userStatDialog}
            onClose={closeDialogUserStat}
            handleClose={closeDialogUserStat}
            fullWidth
            maxWidth="md"
            //scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"    
          >
            <DialogTitle id="scroll-dialog-title"><h2>Статистика запросов</h2></DialogTitle>
            <DialogContent dividers="paper">
              <DialogContentText
                id="scroll-dialog-description"
                tabIndex={-1}
              >
                {(!Array.isArray(userStat))?<Loader/>:(userStat?.length>0)?<UserStatTable style={{height:'auto'}}  data={genUserStatRows()} columns={genUserStatColumns()} />:'Нет данных'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialogUserStat}>Закрыть</Button>
            </DialogActions>
          </Dialog>            

            
            <UsersTable style={{height:'auto'}} data={genRows()} columns={genColumns()} 
            //action={props.action}
            />

        </div>
        :<div style={{padding:'100px', marginLeft:'auto', marginRight:'auto',display:'table'}}><Loader/></div>         
    )

}